import en_US from './en_US'
import zh_CN from './zh_CN'
import ko_KR from './ko_KR'
import ja_JP from './ja_JP'

export default {
  en_US,
  zh_CN,
  ko_KR,
  ja_JP
}
