const zh_CN = {
  tab: {
    market: 'Market',
    computingPower: 'Algorithm',
    manageMoneyMatters: 'Management',
    BTCF: 'BTCF',
    mine: 'My',
    lang: "Switch Chinese",
    balance:"Insufficient balance",
    t1:"Customer service:",
    t2:"Twitter",
    t3:"Telegram",
    t4:"Hash exception"
  },
  inc_menu: {
    walletFirst: 'Link wallet first',
    closure: 'Close',
    bindInvitationCode: 'Bind invitation code',
    pleaseEnter: 'Invitation code',
    confirmBinding: 'Confirm',
  },
  BDCF: {
    t1: 'BTCF Everything Protocol Ecology',
    t2: 'Flexibly incorporating the BTCF ecosystem into Bitcoin\'s computing power.',
    t3: 'Enjoy the dividends of the times',
    t4: 'Exchange',
    t5: 'Quantity',
    t6: 'Introduction to BTCF',
    t7:
      'BTCF is a cutting-edge digital asset that provides unlimited possibilities for users with its unique protocol for connecting everything. Whether it\'s luxury homes, luxury cars, or luxury watches and bags, BTCF can serve as a universal medium of exchange, achieving efficient transactions between people and things. BTCF is not just a digital asset, it is also a wealth tool that can continuously appreciate, bringing lasting returns and investment returns to users. ',
    t8:
      'Through BTCF, users can not only participate in various commodity transactions, but also exchange BTC computing power, further increasing their digital asset reserves. BTCF demonstrates excellent liquidity and security during the trading process, allowing users to confidently engage in high-value transactions and experience efficient and convenient digital financial services. ',
    t9:
      "BTCF is committed to technological innovation and is committed to building a sustainable digital economy ecosystem for its users. It integrates advanced blockchain technology and rich application scenarios, not only improving the efficiency of traditional transaction methods, but also bringing rich potential benefits to users. Choosing BTCF is choosing a brand new way of life, a future that continuously increases wealth. ",
    t10:
      '',
    t11: 'Select currency',
    t12: 'Redeem successful',
    t13: 'Exchange failed',
  },
  computingPower: {
    t0: 'Submit',
    t1: 'Select',
    t2: 'Amount',
    t3: 'Recharge',
    t4: 'Enter',
    t5: 'Number',
    t6: 'Purchase',
    t7: 'Collection',
    t8: 'Received',
    t9: 'Not received',
    t10: 'My computing power',
    t11: 'Number',
    t12: 'Date',
    t13: 'Quantity',
    t14: 'Output',
    t15: 'Tower',
    t16: 'No data yet',
    t17: 'USDT contract address:',
    t18: 'Copy',
    t19: 'Payment address:',
    t20: 'Enter Hash',
    t21: 'Confirm recharge',
    t22: 'Cancel',
    t23: 'Received successfully',
    t24: 'Failed to receive',
    t25: 'Operation successful',
    t26: 'Recharge failed',
    t27: 'Select currency',
    t28: 'Recharge amount',
    t29: 'Configure the payment address first',
    t30: 'Select currency',
    t31: 'Number of redemption units',
    img1: require('../../assets/new/30En.png'),
  },
  manageMoneyMatters: {
    t1: 'Select a time',
    t2: 'Expected return',
    t3: 'Deposit',
    t4: 'Total deposit amount',
    t5: 'Total income',
    t6: 'My Finance',
    t7: 'Currency',
    t8: 'Quantity',
    t9: 'Date',
    t10: 'Type',
    t11: 'Profiting',
    t12: 'Received',
    t13: 'Reinvest',
    t14: 'Balance:',
    t15: 'Select time',
    t16: 'Select currency and time',
    t17: 'quantity',
    t18: 'Minimum quantity cannot be less than',
    t19: 'Deposit successful',
    t20: 'Deposit failed',
    img1: require('../../assets/new/31En.png'),
  },
  market: {
    t1: 'Currency',
    t2: 'Quotes',
    t3: 'Range',
  },
  mine: {
    t1: 'Level:',
    t2: 'My assets',
    t3: 'Withdraw',
    t4: 'BTC to USDT',
    t5: 'Transfer',
    t6: 'Bitcoin Network (BTC) withdrawal address',
    t7: 'Enter the withdrawal address for the BTC chain wallet',
    t8: 'Save',
    t9: 'Copy',
    t10: 'TRON USDT withdrawal address',
    t11: 'Computing power promotion',
    t12: 'Inviter',
    t13: 'My link',
    t14: 'Promotion number',
    t15: 'Piece',
    t16: 'Promotion reward',
    t17: 'Level',
    t18: 'Address',
    t19: 'Power',
    t20: 'Date',
    t21: 'Financial Management Promotion',
    t22: 'Promotion number',
    t23: 'Promotion reward',
    t24: 'Level',
    t25: 'Address',
    t26: 'Deposit',
    t27: 'Transaction record',
    t28: 'Currency',
    t29: 'Quantity',
    t30: 'Type',
    t31: 'Date',
    t32: 'Financial management',
    t33: 'Pay',
    t34: 'Internal transfer',
    t35: 'Transaction',
    t36: 'Recharge',
    t37: 'Withdraw',
    t38: 'Withdraw BTC',
    t39: 'Financial Redemption',
    t40: 'Financial interest',
    t41: 'TRON recharge',
    t42: 'Tron withdrawal',
    t43: 'Financial invitation interest',
    t44: 'Mining income',
    t45: 'Mining invitation income',
    t46: 'Whether to convert BTC to USDT',
    t47: 'Confirm',
    t48: 'Cancel',
    t49: 'Transfer address',
    t50: 'Transfer amount',
    t51: 'Transfer address',
    t52: 'Confirm',
    t53: 'Withdrawal amount',
    t54: 'Confirm withdrawal',
    t55: 'Cannot exceed balance',
    t56: 'Withdrawal processing',
    t57: 'Withdrawal failed',
    t59: 'BTC chain address',
    t60: 'Save successfully',
    t61: 'Save failed',
    t62: 'TRON USDT withdrawal address',
    t64: 'Transfer successful',
    t65: 'Transfer failed',
    t66: 'Node dealers',
    t67:`The digital assets you deposit will enter the platform to obtain BTC miner's dividends, and you will receive different stable income dividends based on the different months of storage.`,
    t68:`Total number of teams`,
    t69:`units`,
    t70:'Total Team Staking Amount',
    t71:'Withdrawal address',
    t72:'Wallet balance',
    t73:'System balance',
    t75:'platform',
    t76:'Destruction quantity',
    t77:'Destroy query address',

  },
}

export default zh_CN
