/**
 * Created by superman on 17/2/16.
 */

export default {
  register: '/topUser/register',
  queryTokensByChainId: '/token/queryTokensByChainId',
  buyOrder: '/power/buyOrder',
  recharge: '/token/recharge',
  getAccounts: '/topAccount/getAccounts',
  getEvaluateBuyMinterAmount:'/power/evaluateBuyMinterAmount',
  getList: '/topStore/getList',
  topStoreOrderorder: '/topStoreOrder/order',
  tokenclaim: '/token/withdraw',
  getInviteInfo: '/topUser/getInviteInfo',
  getInviteList: '/topUser/getInviteList',
  topUser: '/topUser',
  topStoreOrder: '/topStoreOrder/getPage',
  powergetPage: '/power/getPowerOrderList',
  internalTransfer: '/token/internalTransfer',
  exchangeBTC2USDT: '/token/exchangeBTC2USDT',
  powerConfig: '/powerConfig',
  updateWithdrawBTCAddress: '/topUser/updateWithdrawBTCAddress',
  tokengetList: '/token/getList',
  getUnclaimedList: '/topPowerDailyIncome/getUnclaimedList',
  orderInfo: '/power/orderInfo',
  claimAll: '/topPowerDailyIncome/claimAll',
  exchangeUsdt2BTCF: '/token/exchangeUsdt2BTCF',
  getPowerInviteInfo: '/topUser/getPowerInviteInfo',
  getPowerInvitePage: '/topUser/getPowerInvitePage',
  getStoreInviteInfo: '/topUser/getStoreInviteInfo',
  getStoreInvitePage: '/topUser/getStoreInvitePage',
  topStoreOrdergetPage: '/topStoreOrder/getPage',
  topStoreOrderOrderInfo: '/topStoreOrder/orderInfo',
  checkWallet: '/topUser/checkWallet',
  topAccountTxgetPage: '/topAccountTx/getPage',
  exchangeBTC2BTCF: '/token/exchangeBTC2BTCF',
  topPowerDailyIncomeclaim: '/topPowerDailyIncome/claim',
  topStoreOrdercpOrder: '/topStoreOrder/cpOrder' ,
  updateTRONUSDTAddress: '/topUser/updateTRONUSDTAddress' ,
  topNotice:'/topNotice/getList',
  
}
