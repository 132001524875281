const zh_CN = {
  tab: {
    market: '市场',
    computingPower: '算力',
    manageMoneyMatters: '理财',
    BTCF: 'BTCF',
    mine: '我的',
    lang:"切换英文",
    balance:"余额不足",
    t1:"我的客服：",
    t2:"官方推特",
    t3:"电报客服",
    t4:"哈希异常",
    
  },
  inc_menu: {
    walletFirst: '请先链接钱包',
    closure: '关闭',
    bindInvitationCode: '绑定邀请码',
    pleaseEnter: '请输入邀请码',
    confirmBinding: '确认绑定',
  },
  BDCF: {
    t1: 'BTCF万物协议生态',
    t2: '让BTCF生态灵活加入到比特币的算力中，',
    t3: '享时代红利',
    t4: '兑换',
    t5: '请输入数量',
    t6: 'BTCF介绍',
    t7:
      'BTCF一个前沿的数字资产，凭借其独特的链接万物的协议，为用户提供了无限可能。无论是豪宅、豪车，还是奢华名表与名包，BTCF都能够作为一种通用的交换媒介，实现人与物之间的高效交易。BTCF不仅仅是数字资 产，它更是一种能够不断升值的财富工具，为用户带来持久的收益和投资回报。',
    t8:
      '通过BTCF，用户不仅能参与各种商品的交易，还可以兑换BTC算力，进一步增加自己的数字资产储备。BTCF在交易过程中表现出色的流动性和安全性，使得用户能够放心大胆地进行大额交易，体验到高效便捷的数字化金融服务。',
    t9:
      'BTCF立足于科技创新，致力于为用户构建一个可持续发展的数字经济生态系统。它融合了先进的区块链技术和丰富的应用场景，不仅提升了传统交易方式的效率，更为用户带来了丰厚的潜在收益。选择BTCF，就是选择了一种全新的生活方式，一种让财富不断增值的未来。',
    t10:
      '',
    t11: '选择币种',
    t12: '兑换成功',
    t13: '兑换失败',
  },
  computingPower: {
    t0: '提交',
    t1: '请选择',
    t2: '请输入充值数量',
    t3: '充值',
    t4: '请输入',
    t5: '请输入兑换台数',
    t6: '购买算力',
    t7: '一键领取',
    t8: '已领取',
    t9: '未领取',
    t10: '我的算力',
    t11: '编号',
    t12: '日期',
    t13: '数量',
    t14: '产出',
    t15: '台',
    t16: '暂无数据',
    t17: 'USDT合约地址：',
    t18: '复制',
    t19: '收款地址：',
    t20: '请输入Hash',
    t21: '确认充值',
    t22: '取消',
    t23: '领取成功',
    t24: '领取失败',
    t25: '操作成功',
    t26: '充值失败',
    t27: '请选择币种',
    t28: '请输入充值数量',
    t29: '请先配置收款地址',
    t30: '请选择币种',
    t31: '请输入兑换台数',
    img1: require('../../assets/new/30.png'),
  },
  manageMoneyMatters: {
    t1: '请选择时间',
    t2: '预期收益',
    t3: '存入',
    t4: '总存入量',
    t5: '总收益',
    t6: '我的理财',
    t7: '币种',
    t8: '数量',
    t9: '日期',
    t10: '类型',
    t11: '收益中',
    t12: '已领取',
    t13: '复投',
    t14: '余额：',
    t15: '选择时间',
    t16: '请选择币种和时间',
    t17: '请输入数量',
    t18: '最小数量不能低于',
    t19: '存入成功',
    t20: '存入失败',
    img1: require('../../assets/new/31.png'),
    t21:'到期时间',
  },
  market: {
    t1: '币种',
    t2: '行情',
    t3: '算力范围',
  },
  mine: {
    t1: '等级：',
    t2: '我的资产',
    t3: '提现',
    t4: 'BTC兑换USDT',
    t5: '转账',
    t6: 'Bitcoin网络（BTC）提现地址',
    t7: '请输入BTC链钱包提现地址',
    t8: '保存',
    t9: '复制',
    t10: '请输入TRON USDT提现地址',
    t11: '算力推广',
    t12: '邀请人',
    t13: '我的链接',
    t14: '推广人数',
    t15: '个',
    t16: '推广奖励',
    t17: '级别',
    t18: '地址',
    t19: '算力',
    t20: '日期',
    t21: '理财推广',
    t22: '推广人数',
    t23: '推广奖励',
    t24: '等级',
    t25: '地址',
    t26: '存入',
    t27: '交易记录',
    t28: '币种',
    t29: '数量',
    t30: '交易类型',
    t31: '日期',
    t32: '理财',
    t33: '支付',
    t34: '内部转账',
    t35: '交易',
    t36: '充值',
    t37: '提现',
    t38: '提现BTC',
    t39: '理财赎回',
    t40: '理财利息',
    t41: 'TRON充值',
    t42: '波场提现',
    t43: '理财邀请利息',
    t44: '挖矿收益',
    t45: '挖矿邀请收益',
    t46: '是否将BTC兑换成USDT',
    t47: '确认兑换',
    t48: '取消',
    t49: '转账地址',
    t50: '请输入转账数量',
    t51: '请输入转账地址',
    t52: '确认转账',
    t53: '请输入提现数量',
    t54: '确认提现',
    t55: '不可超过余额',
    t56: '提现受理中',
    t57: '提现失败',
    t59: '请输入BTC链地址',
    t60: '保存成功',
    t61: '保存失败',
    t62: '请输入TRON USDT提现地址',
    t64: '转账成功',
    t65: '转账失败',
    t66: '节点经销商',
    t67:'您存入的数字资产，会进入平台获取BTC矿工算力分红，根据存储不同的月份收获不同的稳定收益红利。',
    t68:'团队总台数',
    t69:'台',
    t70:'团队总质押数',
    t71: '提现地址',
    t72:'钱包余额',
    t73:'系统余额',
    t75:'台',
    t76:'销毁量',
    t77:'销毁查询地址',

  },
 
}

export default zh_CN
