const ko_KR = {
    append_0920:{
        usdt_wallet_address:'USDT 지갑 주소',
        wallet_address_length_is_fixed_to_digits_nac: "지갑 주소 길이는 42자리로 고정되어 있습니다.",
        oldNAwalletaddress: "기존 USDT 지갑 주소",
        newNAwalletaddress: "새 USDT 지갑 주소",
        wait: "확인을 기다리는 중...",
       
    },
    applyCommunity: {
        community_application: "커뮤니티 신청",
        application_details: "지원 세부정보",
        independent_community_a_new_starting_point: "독립 커뮤니티, 새로운 출발점",
        destruction: "파괴",
        privilege: "권한",
        top_community: "상위 커뮤니티",
        duty: "의무",
        maintenance_and_development: "유지 및 개발",
        describe: "설명",
        welcome_to_the_Gat_Mining_Pool_community: "Tophis 커뮤니티에 오신 것을 환영합니다",
        when_you_apply_successfully_you_will_get_an_independent_community_network: "성공적으로 지원하면 독립적인 커뮤니티 네트워크를 갖게 됩니다",
        now_start_your_community_career: "이제 커뮤니티 경력을 시작하세요",
        luckyly_you_will_get_a_new_start: "다행히도 새로운 시작을 하게 될 것입니다",
        community_leader: "커뮤니티 리더",
        your_community_members: "귀하의 커뮤니티 구성원",
        they_will_be_close_together: "그들은 서로 가까이 있을 것입니다",
        apply_immediately: "즉시 적용",
        virtual_machine: "클라우드 호스트",

    }
    ,
    applySubsidy: {
        apply_for_accounting_subsidy: "컴퓨팅 파워 보조금 신청",
        application_confirmation_form: "지원 확인 양식",
        apply: "신청",
        Remaining_application_times: "남은 신청 시간",
        fee_required_for_application: "신청에 필요한 수수료",
        effective_time_of_subsidy: "보조금 유효 시간",
        some_tips: "몇 가지 팁",
        if_your_twice_calculated_allowance: "당신의 2배 계산 허용량",
        lasts_for_2_years: "2년 동안 지속됨",
        if_the_income_does_not_reach_2000u_you_can_directly_apply_for_the_minimum_pool_subsidy: "소득이 2000U에 도달하지 않은 경우 최소 풀 보조금을 직접 신청할 수 있습니다.",
        but_it_is_a_first_come_first_served_principle_if_the_balance_of_the_minimum_pool_is_consumed_it_will_stop: "하지만 선착순 원칙이므로 최소 풀의 잔액이 소모되면 중지됩니다.",
        Confirm_application: "신청 확인",
        faced_a_problem: "문제 발생",
        reminder: "알림",
        are_you_sure_to_apply: "지원하시겠습니까?",
        confirm: "확인",
        cancel: "취소",
        congratulations_the_transaction_was_successfully_sent: "축하합니다. 거래가 성공적으로 전송되었습니다!",
        copy_hash: "해시 복사",
        close: "닫기",
    }
    ,
    articleList: {
        real_time_articles: "실시간 기사",
        see: "보기",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        close: "닫기",

        /*1*/
        notice: "공지사항",
        search_title: "검색 제목",
        reminder: "알림",
        data_loading: "데이터 로딩"

    },

    /*1*/
    articleContent: {
        article_details: "기사 세부정보",
        return: "반환",
    }
    ,
    bind: {
        wallet_binding: "지갑 바인딩",
        bind_bsc_wallet: "GAT 지갑 연결",
        recharge_at_any_time: "언제든지 충전하세요",
        wallet_association_binding_at_one_time: "지갑 연결, 한 번에 바인딩",
        public_chain_wallet_binding: "퍼블릭 체인 지갑 바인딩",
        bind_public_chain_wallet_address: "퍼블릭 체인 지갑 주소 바인딩",
        more_public_links_will_be_connected_one_time_binding: "은 더 많은 공개 링크에 연결될 것입니다. 한 번 바인딩합니다.",
        transfer_at_any_time: "언제든지 전송",
        current_wallet: "현재 지갑",
        click_the_link_wallet_at_the_top_right: "지갑을 연결하려면 오른쪽 상단을 클릭하십시오.",
        must: "반드시",
        bind_public_chain: "공개 체인 연결",
        readonly: "읽기 전용",
        wallet_address: "NA 지갑 주소",
        the_transaction_is_being_confirmed: "거래가 확인 중입니다...",
        Confirm_binding: "바인딩 확인",
        back_to_home: "홈으로 돌아가기",
        bound: "바운드",
        public_chain_name: "퍼블릭 체인 이름",
        binding_status: "바인딩 상태",
        unbound: "언바운드",
        copy_address: "주소 복사",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        please_link_the_wallet_first: "지갑을 먼저 연결해주세요",
        please_enter_the_bsc_wallet_address: "NA 지갑 주소를 입력하세요",
        the_wallet_address_is_illegal: "지갑 주소가 잘못되었습니다.",
        wallet_address_length_is_fixed_to_42_digits: "지갑 주소 길이는 34자리로 고정되어 있습니다.",
        reminder: "알림",
        congratulations_the_transaction_was_successfully_sent: "축하합니다! 거래가 성공적으로 전송되었습니다!",
        copy_hash: "해시 복사",
        close: "닫기",

        wallet_binding_list: "지갑 바인딩 목록",
        public_chain: "퍼블릭 체인",
        wallet: "지갑",
        state: "상태",
        data_loading: "데이터 로딩"
    }
    ,
    contact: {
        contact_us: "문의하기",
        kuala_lumpur_malaysia: "말레이시아 쿠알라룸푸르",
        service_and_maintenance_center: "서비스 및 유지보수 센터",
        telephone_call: "전화",
        middle_east_dubai: "중동 두바이",
        sales_center: "판매 센터",
        other_contact_information: "기타 연락처 정보",

        keep_in_contact: "계속 연락하기",
        fill_in_the_form_and_contact_us: "문의 양식을 작성하세요",
        nickname: "닉네임",
        mailbox: "사서함",
        content: "내용",
        send_out: "보내기",
        contact_address: "연락처 주소",
        social_data: "소셜 데이터",
    }
    ,
    faq: {
        common_problem: "일반적인 문제",
        really_we_are_often_asked_this_question: "정말로, 우리는 종종 이 질문을 받습니다",
        we_made_this_small_part_to_help_you_determine_what_you_need_faster: "필요한 것이 무엇인지 더 빨리 결정할 수 있도록 이 작은 부분을 만들었습니다.",

        some_common_problems: "일반적인 문제",
        you_are_also_experience_problems: "또한 문제가 발생했습니다",
        feel_free_to_contact_us: "언제든지 연락주세요",
        send_mail: "메일 보내기",
    }
    ,
    giveUp: {
        abandonment_of_financial_subsidies: "재정 보조금 포기",
        are_you_sure_to_give_up_the_consensus_computing_subsidy_of_id_vps: "클라우드 호스트 의 합의 컴퓨팅 보조금을 포기하시겠습니까?",
        if_free_transfer_is_required_during_the_lease_contract_it_will_be_deemed_as_an_opportunity_to_voluntarily_give_up_the_consensus_guarantee: "임대차 계약 기간 동안 무료 양도가 필요한 경우 자발적으로 합의 보장을 포기할 기회로 간주합니다.",
        if_confirmed_the_transfer_extraction_function_can_be_started: "확인되면 전송 추출 기능을 시작할 수 있습니다.",
        in_effect: "적용되고 있습니다",
        do_not_pay_repeatedly: "반복적으로 지불하지 마십시오",
        Confirm_abandonment: "포기 확인",
        return_to_vps_list: "클라우드 호스트 목록 반환",
        reminder: "알림",
        are_you_sure_you_want_to_give_up_if_you_dont_know_whats_going_on: "포기하시겠습니까? 무슨 일이 일어나고 있는지 모른다면",
        please_close_this_window: "이 창을 닫아주세요",
        confirm: "확인",
        cancel: "취소",
        congratulations_the_transaction_was_successfully_sent: "축하합니다! 바인딩에 성공했습니다!",
        copy_hash: "해시 복사",
        close: "닫기",

        tips: "팁",
        operating_tips: "운영 팁",
        the_current_interface_has_sensitive_operations_Please_confirm_before_operating: "현재 인터페이스에 민감한 작업이 있습니다. 작업하기 전에 확인하십시오.",
        are_you_sure_to_give_up: "당신은 확실히 포기합니다",
        tfms_consensus_computing_subsidy: "클라우드 호스트 의 합의 컴퓨팅 보조금인가요?",
        return: "반환",
    }
    ,
    home: {
        nac_daily_closing_price: "GAT 일일 종가",
        account_level: "계정 수준",
        add_date: "등록일",
        total_value: "총 가치",
        total: "총계",
        daily_output: "일일 출력",
        account_nickname: "계정 닉네임",
        Recommended_account: "추천 계정",
        evangelist_level: "공유자 수준",
        direct_preaching: "직접 푸시 공유",
        evangelical_exchange_coupon: "쿠폰 공유",
        loss_of_preaching: "손실 공유",
        Sermon_status: "공유 상태",
        free_account: "무료 계정",
        Paid_account: "유료 계정",
        due_account: "미결제 계정",
        sermon: "설교",
        withdrawal: "철회",
        bill: "청구서",
        recharge: "재충전",
        account_assets: "계정 자산",
        here_the_asset_details_of_the_account_will_be_listed: "여기에 계정의 자산 세부 정보가 나열됩니다.",
        wallet: "지갑",
        withdrawable_currency: "수입 지갑",
        deposit_wallet: "지갑 충전",
        withdrawable_currency_leasable: "인출 가능 및 임대 가능",
        community_and_team: "커뮤니티 및 팀",
        embrace_more_community_members: "커뮤니티 회원을 더 포용",
        the_platform_will_provide_new_growth_space_and_create_a_new_starting_point_for_you: "플랫폼은 새로운 성장 공간을 제공하고 새로운 출발점을 만들 것입니다",
        my_team: "내 팀",
        invitation_link: "초대 링크",
        hey_hello: "안녕하세요",
        welcome_to_Gat_Mining_Pool_Please_click_the_icon_in_the_upper_right_corner_to_connect_the_wallet: "클라우드 호스트에 오신 것을 환영합니다. 오른쪽 상단 모서리에 있는 아이콘을 클릭하여 지갑을 연결하세요",
        close: "닫기",
        wallet_not_connected: "지갑이 연결되지 않음",
        your_browser_does_not_support: "당신의 브라우저는 지원하지 않습니다",
        send_data: "데이터 보내기",
        ending_data: "데이터 보내기",
        data_returned: "반환된 데이터",
        error: "오류",

        wallet_address: "지갑 주소",
        team: "팀",
        number_of_leases: "임대 수",
        number_of_direct_referrals: "총 직접 추천 수",
        team_account_total: "팀 계정 합계",
        team_tfm_quantity: "팀 클라우드 호스트 수량",
        account_wallet: "계정 지갑",
        account_details_: "계정 정보",
        left_and_right_sliding: "좌우로 슬라이딩",
        account_status: "계정 상태",
        income_sharing_coupon: "소득 쿠폰 공유",
        loss_of_sharing: "공유 손실",
        platform_interconnection: "플랫폼 상호 연결",
        invite_friends: "친구 초대",
        invite_your_friends_and_earn: "친구를 초대하고 적립하세요",
        invite_now: "지금 초대하기",
        join_our_discussion_group: "토론방에 가입하세요",
        lets_talk_about_the_market_and_strategy: "시장과 전략에 대해 이야기해 봅시다",
        join_now: "지금 가입하기",
        view_groups: "그룹 보기",
        follow_us: "팔로우",
        follow_social_accounts_closely: "소셜 계정을 면밀히 팔로우하세요",
        please_set_it_now: "계정 보안을 위해 결제 비밀번호를 먼저 설정해주세요!",
        determine: "확인",
        share_voucher: "수익 한도",
        community_performance: "커뮤니티 공연",
    }
    ,
    inc_menu: {
        home_page: "홈",
        lease: "임대",
        data: "데이터",
        set_up: "설정",
        recharge: "재충전",
        withdrawal: "철회",
        invitation: "초대장",
        binding: "바인딩",
        connect_wallet: "지갑 연결",
        disconnect_wallet: "지갑 연결 해제",
        team: "팀",
        notice: "공지사항",
        bill: "청구서",
        close: "닫기",
        welcome_to_Gat_Mining_Pool_Please_click_the_icon_in_the_upper_right_corner_to_connect_the_wallet: "클라우드 호스트 에 오신 것을 환영합니다. 오른쪽 상단 모서리에 있는 아이콘을 클릭하여 지갑을 연결하세요",
    }
    ,
    index: {}
    ,
    invite: {
        invitation_confirmation: "초대 확인",
        welcome_to_join: "가입을 환영합니다",
        a_data_center_worthy_of_your_trust_the_platform_will_create_a_new_starting_point_for_you: "당신이 신뢰할 수 있는 데이터 센터, 플랫폼이 당신을 위한 새로운 출발점을 만들 것입니다",
        current_wallet: "현재 지갑",
        click_the_link_wallet_at_the_top_right: "지갑을 연결하려면 오른쪽 상단을 클릭하십시오.",
        must: "반드시",
        invitation_wallet: "초대장 지갑",
        nickname: "닉네임",
        Confirm_binding: "바인딩 확인",
        back_to_home: "홈으로 돌아가기",
        please_link_the_wallet_first: "지갑을 먼저 연결해주세요",
        the_current_wallet_cannot_be_the_same_as_the_invitation_wallet_Please_switch_wallets: "현재 지갑은 초대 지갑과 같을 수 없습니다. 지갑을 바꾸십시오.",
        please_enter_the_invitation_wallet_address: "초대 지갑 주소를 입력하세요",
        the_invitation_wallet_is_illegal: "초대 지갑이 불법입니다",
        the_invitation_wallet_has_a_fixed_length_of_34_digits: "초대 지갑의 길이는 42자리로 고정되어 있습니다.",
        please_enter_your_nickname: "닉네임을 입력하세요",
        nickname_length_cannot_exceed: "닉네임 길이는 50자를 초과할 수 없습니다.",
        you_have_bound: "귀속했습니다",
        reminder: "알림",
        congratulations_the_transaction_was_successfully_sent: "축하합니다. 거래가 성공적으로 전송되었습니다!",
        account_save_ok: "축하합니다! 계정 등록 성공!",
        copy_hash: "해시 복사",
        close: "닫기",

        wallet_binding: "지갑 바인딩",
        the_local_data_center_in_south_korea_is_safe_and_reliable: "싱가포르의 안전하고 신뢰할 수 있는 로컬 데이터 센터",
        outstanding_efficiency: "뛰어난 효율성",
        click_on_the_top_right_to_connect_the_wallet: "지갑을 연결하려면 오른쪽 상단을 클릭하세요",
        required: "필수",
    }
    ,
    nodeList: {
        days: "일 수",
        lease_orders: "임대 주문",
        No1: '클라우드 호스트 1번',
         No2: '클라우드 호스트 2번',
         needDeposit: '귀하의 계정은 현재 GAT 예치금을 지불해야 합니다:',
         pleasePay: '가급적 빨리 결제해주세요!',
         oneClickDepositPayment: '원클릭 입금',
         enough_quantity: '현재 nac 수량이 부족합니다. 보충해주세요!',
         successful_deposit_payment: '클릭 한 번으로 입금 성공',
         failed_to_pay_deposit: '한 번의 클릭으로 입금 실패',
        my: "내 클라우드 호스트",
        total: "클라우드 호스트 합계",
        expiring_soon: "곧 만료됨",
        to_be_activated: "활성화하려면",
        show_all: "모두 보기",
        hide_paid: "유료 숨기기",
        pay_all: "모두 지불",
        reminder: "알림",
        the_payment_transaction_is_being_uploaded_Please_refresh_later: "결제 거래가 업로드 중입니다. 나중에 새로고침하세요",
        note_you_currently_exist: "참고: 당신은 현재 존재합니다",
        vps_orders_have_not_been_paid: "클라우드 호스트 주문이 지불되지 않았습니다",
        entryintoforce_time: "강제 적용 시간",
        great_than: "보다 큼",
        total_value: "총 가치",
        computeal_wallet: "전산 지갑",
        transferred: "전송됨",
        abandonment_of_financial_subsidies: "재정 보조금 포기",
        wallet_transfer: "지갑 송금",
        apply_for_subsidy: "보조금 신청",
        Calculation_income_list: "전산 소득 목록",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        data_loading: "데이터 로딩",
        return: "반환",
        the_referrer_of_the_current_account_is_not_in_official_status: "현재 계정의 추천인이 공식 상태가 아닙니다.",
        the_total_number_of_nac_required_for_orders_waiting_to_be_paid_is_incorrect: "지불 대기 중인 주문에 필요한 총 GAT 수가 올바르지 않습니다.",
        the_order_waiting_for_payment_is_empty: "결제 대기 중인 주문이 비었습니다.",
        payment_confirmation: "결제 확인",
        you_confirm_to_pay_selfdetail_totalpaidtotaldeposit_for_contract_orders: "계약 주문에 대한 GAT 보증금 지불을 확인했습니다. 총 주문 수: ",
        confirm: "확인",
        cancel: "취소",
        congratulations_the_transaction_was_successfully_sent: "축하합니다. 거래가 성공적으로 전송되었습니다!",
        copy_hash: "해시 복사",
        close: "닫기",
        accumulative_demand_of_current_unpaid_orders: "현재 미결제 주문 GAT의 누적 수요",
        current_wallet_balance: "현재 지갑 잔액",
        please_transfer_sufficient: "현재 인스턴스에 충분한 양을 이체하세요",

        to_be_paid: "결제 예정",
        paid: "지불",
        list: "목록",
        output_details: "출력 세부 정보",
        Lease_now: "지금 임대"
    },
    outputList: {
        Calculation_income_list: "전산 소득 목록",
        you_can_query_the_details_of_vps_output_on_this_page: "이 페이지에서 클라우드 호스트 출력의 세부 정보를 쿼리할 수 있습니다.",
        output_income: "매출 수입",
        code: "코딩",
        quantity: "수량",
        time: "시간",
        explain: "설명",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        data_loading: "데이터 로딩",

        output_list: "출력 목록",
        details_of_daily_output: "일별 출력 세부 정보",
        output_details: "출력 세부 정보",
        hash: "해시",
        reminder: "알림",
    }
    ,
    accelerateList: {
        accelerateList: '가속 목록',
        accelerationDetails: '가속내역',
        dynamicAmount: '동적 보상 수량',
        dynamicDay: '동적 가속 일수',
        remark: '참고 정보',

    },
    pay: {
        recharge: "재충전",
        wallet: "지갑",
        bound: "바운드",
        click_to_bind: "바인딩하려면 클릭",
        please_do_not_use_the_exchange_to_recharge: "충전을 위해 교환을 사용하지 마세요",
        copy_recharge_contract_address: "충전 승인 버튼을 클릭합니다.",
        open_the_wallet_supporting_bsc_chain: "NA 체인을 지원하는 NaStation 지갑 열기",
        enter_the_contract_address_and_recharge_quantity: "지갑 주소와 충전 수량 입력",
        transfer_sent_to_usdt_bsc: "USDT(BSC) 송금 보내기",
        wait_patiently_for_the_system_to_confirm_receipt: "시스템이 영수증을 확인하기를 참을성 있게 기다리는 중",
        copy_succeeded_Please_do_not_use_the_exchange_to_recharge: "복사에 성공했습니다! 충전을 위해 교환기를 사용하지 마세요",
        copy_contract_address: "지갑 주소 복사",
        recharge_list: "충전 목록",
        click_to_recharge: "충전하려면 클릭하세요",
        button: "버튼",
        enter_nac_recharge_quantity: "GAT 충전 수량 입력",
        authorize_transactions_in_the_popup_interface: "팝업 인터페이스에서 거래 승인",
        address_of_qr_code_request: "QR 코드 요청 주소",

        see: "보기",
        wallet_address: "지갑 주소",
        date: "날짜",
        state: "상태",
        reminder: "알림",

        authorized_recharge_USDT: "승인된 USDT 충전",
        copy_wallet_address: "지갑 주소 복사",
        please_enter_the_number_of_USDTs_to_recharge: "충전할 USDT 수량을 입력하세요",
        define: "확인",
        cancel: "취소",
        amount_error: "금액 오류",
        please_enter_the_correct_number_of_USDTs_to_recharge: "충전할 정확한 USDT 수량을 입력하세요",
        Insufficient_BNB_absenteeism_fees: "BNB 결근 수수료가 부족합니다.",
        authorizing_payment: "결제 승인",
        transaction_is_being_confirmed: "거래가 확인 중입니다.",
    }
    ,
    payNac: {
        recharg: "재충전",
        copy_wallet_address: "지갑 주소 복사",
        please_do_not_use_the_exchange_to_recharge: "충전을 위해 교환기를 사용하지 마세요.",
        copy_recharge_contract_address: "지갑 주소 복사",
        open_the_wallet_supporting_bsc_chain: "NA 체인을 지원하는 NaStation 지갑 열기",
        enter_the_contract_address_and_recharge_quantity: "계약 주소 및 충전 금액 입력",
        transfer_sent_to_usdt_bsc: "GAT 전송 보내기",
        wait_patiently_for_the_system_to_confirm_receipt: "시스템이 계정을 확인할 때까지 참을성 있게 기다리십시오.",

    },
    payList: {
        recharge_details: "충전 세부 정보",
        you_can_query_the_recharge_details_on_this_page: "이 페이지에서 충전 세부 정보를 조회할 수 있습니다",
        rechargeable_wallet: "충전식 지갑",
        recharge: "재충전",
        public_chain_name: "퍼블릭 체인 이름",
        recharge_quantity: "충전 수량",
        recharge_hash: "해시 충전",
        recharging_time: "충전 시간",
        explain: "설명",
        copy_hash: "해시 복사",
        see: "보기",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        data_loading: "데이터 로딩",

        recharge_list: "충전 목록",
        reminder: "알림",
        your_recharge_will_not_arrive_immediately: "충전이 즉시 도착하지 않습니다",
        please_wait_patiently_for_the_system_to_confirm_receipt: "시스템이 영수증을 확인할 때까지 기다려 주십시오",
    }
    ,
    register: {
        lease: "임대",
        Lease_vps_virtual_machine: "클라우드 호스트 클라우드 호스트 임대",
        we_are_guiding_you_into_the_leasing_process: "우리는 당신을 임대 절차로 안내하고 있습니다",
        maximum_leasable_quantity: "최대 임대 가능 수량: ",
        quantity: "수량",
        must: "반드시",
        next_step: "다음 단계",
        payment_wizard: "USDT 결제 마법사",
        you_are_currently_required_to_authorize_payment: "현재 USDT로 결제를 승인해야 합니다",
        to_be_paid: "USDT 지급 예정",
        current_fusd_balance: "현재 USDT 잔액",
        current_nac_balance: "현재 GAT 잔액",
        Confirm_payment: "결제 확인",
        payment_completed: "결제 완료",
        the_system_is_detecting_your_order_Please_wait_patiently_for_the_transaction_to_be_linked: "시스템이 귀하의 주문을 감지하고 있습니다. 거래가 연결될 때까지 기다려 주십시오.",
        when_the_confirmation_is_successful_each_copy_will_be_generated_for_you: "확인에 성공하면 각 사본이 생성됩니다",
        contract_later: "계약! 나중에",
        please_pay_the_deposit_nac_to_activate_each_contract: "각 계약을 활성화하려면 예치금 GAT를 지불하세요",
        continue_to_invest: "계속 투자하기",
        return_to_vps_list: "클라우드 호스트 목록 반환",
        please_enter_the_lease_quantity: "임대 수량을 입력하세요",
        close: "닫기",
        operation_confirmation: "작업 확인",
        you_have_not_been_invited_yet_this_is_the_first_step_you_need_to_complete_before_investing: "아직 초대받지 않았습니다. 투자하기 전에 완료해야 하는 첫 번째 단계입니다.",
        are_you_sure_to_jump_to_the_invitation_binding_page: "초대 바인딩 페이지로 이동하시겠습니까?",
        jump: "점프",
        the_invitation_account_of_the_current_account_does_not_exist: "현재 계정의 초대 계정이 존재하지 않습니다.",
        tips: "알림",
        insufficient_assets_please_supplement: "USDT 자산이 부족합니다. 먼저 USDT를 충전하세요!",
        recharge_immediately: "즉시 충전",
        reminder: "알림",
        congratulations_the_transaction_was_successfully_sent: "축하합니다. 거래가 성공적으로 전송되었습니다!",
        copy_hash: "해시 복사",

        rent_tfm_virtual_machine: "클라우드 호스트 클라우드 호스트 임대",
        needed: "필요하다",
        deposit: "보증금",
        wait_patiently_for_the_link: "링크가 적용될 때까지 참을성 있게 기다리십시오",
        the_system_is_checking_your_order_Please_wait_patiently_for_confirmation: "시스템에서 주문을 확인하고 있습니다. 확인을 위해 잠시 기다려 주십시오.",
        when_the_order_is_confirmed_the_system_will_generate_each_copy_for_you: "주문이 확인되면 시스템에서 각 사본을 생성합니다",
        contract: "",
        finally_you_need_to_pay_in_batches: "마지막으로 클라우드 호스트 주문에 필요한 GAT를 일괄적으로 지불해야 합니다",
        contractual: "",
        return_to_tfm_list: "클라우드 호스트 목록 반환",
        No1: '클라우드 호스트 1번: 500',
        No2: '클라우드 호스트 2: 500',
        product_type: '제품 유형',
        successful: '축하합니다. 클라우드 호스트 대여에 성공했습니다!',
        myTFM: '내 클라우드 호스트',
        insufficientNumberOfNacs: 'nac 수량 부족',
        computing_power: '컴퓨팅 파워',
         the_remaining_amount: '남은 금액: ',
      


    }
    ,
    settings: {
        set_up: "설정",
        language_selection_english: "영어",
        language_selection_malay: "말레이어",
        language_selection_simplified_chinese: "중국어(간체)",
        language_selection_traditional_chinese: "중국어(번체)",
        please_select_the_international_language_you_are_familiar_with: "페이지의 국제 언어",
        real_time_articles: "실시간 기사",
        read_more_about_the_platform: "뉴스 및 알림",
        night_mode: "야간 모드",
        switch_to_night_mode: "야간 모드로 전환",
        daytime_mode: "주간 모드",
        switch_to_day_mode: "요일 모드로 전환",
        common_problem: "일반적인 문제",
        find_answers_for_you: "당신을 위해 준비된 답변 찾기",
        upgrade_maintenance: "업그레이드 유지 관리",
        the_system_is_being_upgraded_Please_check_the_status: "시스템이 업그레이드 중입니다. 상태를 확인하십시오.",
        contact_us: "문의하기",
        you_will_find_some_contact_information: "연락처 정보를 찾을 수 있습니다",
        community_settlement: "커뮤니티 정착지",
        accept_millions_of_communities_and_witness_ecological_development_together: "수백만 개의 커뮤니티를 포용하고 생태계 발전을 함께 목격하세요",
        when_you_choose_to_trust_Gat_Mining_Pool_you_will_expand_more_contacts_and_increase_unlimited_wealth: "Tophis 을 신뢰하기로 선택하면 더 많은 연락처를 확장하고 무한한 부를 늘릴 수 있습니다.",
        start_a_new_career_together: "함께 새로운 커리어 시작",
        apply_to_the_community: "커뮤니티에 신청하기",
        language_selection: "언어 선택",
        please_select_the_interface_language_you_are_familiar_with: "익숙한 인터페이스 언어를 선택하세요",
        english: "영어 [영어]",
        malay: "말레이어[멜라유]",
        simplified_chinese: "중국어(간체)",
        traditional_chinese: "중국어(번체)",

        my: "내",
        routine: "일상",
        language: "언어",
        korean: "한국어",
        japanese: "일본어",
        notice: "공지사항",
        style: "스타일",
        select_page_language: "페이지 언어 선택",
        close: "닫기",
    }
    ,
    share: {
        invitation_link: "초대 링크",
        Invitation_to_join: "Tophis 가입 초대",
        let_your_links_pass_on_to_everyone_and_build_a_continuous_network: "링크를 모두에게 전달하고 지속적인 네트워크를 구축하세요",
        invitation_process: "초대 과정",
        download_nastation_wallet: "토큰포켓 지갑 다운로드",
        enter_the_invitation_link_on_the_discovery_page: "검색 페이지에 초대 링크 입력",
        Confirm_to_bind_the_invitation_wallet: "초대 지갑 바인딩 확인",
        invitation_qr_code: "초대장 QR 코드",
        note_that_the_domain_name_address_of_the_current_page_used_is_generated_dynamically: "현재 사용된 페이지의 도메인 이름 주소는 동적으로 생성됩니다.",
        copy_invitation_link: "초대 링크 복사",

        invitation: "초대장",
        quick_invite_registration: "빠른 초대 등록",
        copy_quick_invitation_link: "빠른 초대 링크 복사",
        download_a_wallet_that_supports_BSC_chain: "BSC 체인을 지원하는 지갑 다운로드",

    }
    ,
    systemStat: {
        platform_statistics: "플랫폼 통계",
        mission_fund_pool: "미션 기금 풀",
        balance: "균형",
        total_number_of_communities: "총 커뮤니티 수",
        consensus_minimum_pool: "합의된 최소 풀",
        capacity_summary: "용량 요약",
        total_node_output: "전체 노드 출력",
        total_quantity: "총 수량",
        leased: "임대",
        daily_output: "매일 출력",
        nacday: "GAT/일",
        deposit_quantity: "입금 수량",
        piece: "",
        day: "일",

        data_statistics: "데이터 통계",
        Sermon_fund: "설교 기금",
        system_data: "시스템 데이터",
        server_time_zone: "서버 시간대:",
        server_time: "서버 시간:",

    }
    ,
    teamList: {
        direct_invitation: "직접 초대",
        thank_you_for_your_efforts_we_will_show_you_the_list_of_accounts_directly_invited: "노력해 주셔서 감사합니다! 직접 초대된 계정 목록을 보여드리겠습니다",
        total_direct_deduction: "총 직접 공제",
        total_number_of_sermons: "총 설교 수",
        account_list: "계정 목록",
        there_are_3_colors: "3가지 색상이 있습니다",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",

        team_list: "팀 목록",
        my_level: "내 레벨",
        click_to_go_to_the_invitation_interface: "클릭하여 초대 인터페이스로 이동",
        total_number_of_collisions: "총 충돌 수",
        crash_balance: "충돌 균형",
        details: "세부 정보",
        total_number_of_collisions_exceeding_the_upper_limit: "상한을 초과한 총 충돌 수",
        close: "닫기",
        team_members: "팀 구성원",
        reminder: "알림",
        data_loading: "데이터 로딩",


        commend_account: "총 추천",
        team_account: "총 팀 수",
        my_entry: "내 항목",
        team_entry: "팀 엔트리",
        team_performance: "총 팀 성과",
        performance_information: "성능 정보",
        my_achievements: "내 업적",
        regional_performance: "지역 실적",
        community_performance: "커뮤니티 공연",
        total_performance: "총 실적",
    }
    ,
    transferList: {
        bill_details: "청구서 세부정보",
        you_can_query_the_bill_of_assets_on_this_page: "이 페이지에서 자산 명세서를 조회할 수 있습니다",
        account_assets: "계정 자산",
        code: "코딩",
        quantity: "수량",
        time: "시간",
        explain: "설명",
        copy_encoding: "인코딩 복사",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        data_loading: "데이터 로딩",

        bill_list: "계산 목록",

        billing_type: "청구 유형",

        bill_amount: "어음 금액",

        billing_time: "청구 시간",

        bill_Hash: "어음 해시",

        bill_description: "계산 설명",

        hash: "해시",

        reminder: "알림",


    }
    ,
    upgrade: {
        upgrade_maintenance: "업그레이드 유지 관리",
        maintenance_mode: "유지관리 모드",
        well_be_back_soon_there_is_a_small_problem_with_our_system: "시스템에 작은 문제가 있어 곧 다시 돌아오겠습니다.",
        Thank_you_for_your_patience: "기다려주셔서 감사합니다",
        back_to_home: "홈으로 돌아가기",
        refresh_page: "페이지 새로 고침",

        system_upgrade: "시스템 업그레이드",
        upgrade_and_maintenance: "업그레이드 및 유지 관리",
        in_order_to_provide_better_services_we_have_to_upgrade_some_functions: "더 나은 서비스를 제공하기 위해 일부 기능을 업그레이드해야 합니다.",
        please_wait_patiently_for_the_system_to_open: "시스템이 열릴 때까지 기다려 주십시오",
        try_refreshing: "새로고침 시도",
    }
    ,
    walletCross: {
        wallet_transfer: "지갑 송금",
        choice: "선택",
        source_wallet: "소스 지갑",
        computeal_wallet: "전산 지갑",
        target_wallet: "대상 지갑",
        wallet: "지갑",
        maximum_transferable_quantity: "최대 양도 가능 수량",
        transfer_quantity: "이체 수량",
        must: "반드시",
        Confirm_transfer: "이체 확인",
        please_enter_the_transfer_quantity_greater_than: "0보다 큰 이체 수량을 입력하세요",
        please_enter_the_transfer_quantity: "이체 수량을 입력하세요",
        reminder: "알림",
        are_you_sure_to_transfer: "전송하시겠습니까?",
        confirm: "확인",
        cancel: "취소",
        congratulations_the_transaction_was_successfully_sent: "축하합니다. 거래가 성공적으로 전송되었습니다!",
        copy_hash: "해시 복사",
        close: "닫기",

        balance: "균형",
        service_charge: "서비스 요금",
        copy_hash_popup_window: "해시 팝업 창 복사",
    }
    ,
    withdraw: {
        withdrawal: "철회",
        withdrawal_wallet: "출금 지갑",
        wallet_address: "지갑 주소",
        withdrawal_address: "출금 주소",
        readonly: "읽기 전용",
        maximum_withdrawal: "최대 출금",
        withdrawal_quantity: "출금 수량",
        must: "반드시",
        some_tips: "몇 가지 팁",
        click_to_confirm: "확인하려면 클릭하세요",
        button: "버튼",
        authorize_transactions_in_the_popup_interface: "팝업 인터페이스에서 거래 승인",
        wait_patiently_for_the_system_to_finish_processing: "시스템이 처리를 마칠 때까지 참을성 있게 기다리는 중",
        confirm_withdrawal: "철회 확인",
        copy_hash: "해시 복사",
        view_list: "목록 보기",
        the_withdrawal_address_cannot_be_empty_Please_bind_the_public_chain_wallet_address_first: "출금 주소는 비워둘 수 없습니다. 퍼블릭 체인 지갑 주소를 먼저 바인딩하십시오.",
        please_enter_the_correct_withdrawal_quantity: "올바른 인출 수량을 입력하세요",
        reminder: "알림",
        congratulations_the_transaction_was_successfully_sent: "축하합니다! 현금인출 신청이 성공적으로 완료되었습니다!",
        close: "닫기",
        apply_for_withdrawal: "철회 신청",
        wallet_authorized_withdrawal__waiting_for_confirmation_on_the_chain: "지갑 인출 승인 - 체인에서 확인 대기 중",
        withdrawal_token: "출금지갑",
        withdrawal_address_required: "출금주소(필수)",
        current_wallet_address: "현재 지갑 주소",
        withdrawal_quantity_required: "출금수량(필수)",
        minimum_withdrawal: "최소 출금",
        service_charge: "서비스 요금",
        copy_hash_popup_window: "해시 팝업 창 복사",
        the_minimum_withdrawal_quantity_is: "최소 출금 수량",
        kind_ips: "따뜻한 팁",
        be_charged1: "출금 금액이 100 미만인 경우 수수료가 부과됩니다: 1 GAT",
        be_charged2: "출금 금액이 100보다 크거나 같으면 수수료가 부과됩니다: 1% GAT",
        be_charged3: "출금을 신청할 때 체인에서 확인될 때까지 기다려 주세요!",
        pay_psw: "결제 비밀번호"
    }
    ,
    withdrawList: {
        withdrawal_details: "출금내역",
        you_can_query_the_details_of_withdrawal_on_this_page: "이 페이지에서 출금 내역을 조회할 수 있습니다",
        withdrawal_wallet: "출금 지갑",
        withdrawal: "철회",
        withdrawal_code: "출금 코드",
        withdrawal_quantity: "출금 수량",
        withdrawal_address: "출금 주소",
        withdrawal_time: "철회 시간",
        withdrawal_status: "철회 상태",
        completed: "완료",
        incomplete: "미완성",
        on_chain_hash: "온체인 해시",
        completion_time: "완료 시간",
        explain: "설명",
        copy_hash: "해시 복사",
        no_more_data_loaded: "더 이상 데이터가 로드되지 않음",
        return: "반환",
        data_loading: "데이터 로딩",

        withdrawal_list: "출금 목록",
        asset_type: "자산 유형",
        quantity: "수량",
        hash: "해시",
        time: "시간",
        reminder: "알림",
    }
    ,
    include: {
        ai_data_center: "",
        bd: "빅 데이터",
        metaverse: "메타버스",
        hc: "하드웨어 컴퓨팅",
        project_provide: "프로젝트 제공",
        bank_trust: "신탁 은행",
        home_page: "홈페이지",
        lease: "임대",
        data: "데이터",
        settings: "설정",
        back: "반환",
        link_wallet: "지갑 연결",
        disconnect: "연결 끊기",
        download_wallet: "지갑 다운로드",
        kind_tips: "팁",
        open_by_nastation: "\"TokenPocket\" 지갑에서 dapp을 열어주세요",
        operation_confirmation: "작업 확인",
        are_you_disconnect: "지갑 연결을 해제하시겠습니까?",
        cancel: "취소",
        load_more: "더 불러오기",
        close: "닫기"
    }
    ,
    jsModule: {
        coming_soon: "곧 출시",
        copy: "성공적으로 복사되었습니다"
    },
    setPayPsw: {
        payment_password: "결제 비밀번호",
        set_payment_password: "결제 비밀번호 설정",
        tips: "결제비밀번호는 영문(대소문자), 숫자, 특수문자 8자 이상으로 구성되어 있습니다",
        confirm_payment_password: "결제 비밀번호 확인",
        submit: "제출",
        has_been_set: "결제 비밀번호가 설정되었습니다",
        suspayment_password: "축하합니다! 결제 비밀번호를 성공적으로 설정하셨습니다!",
        password_cannot_be_empty: "비밀번호는 비워둘 수 없습니다.",
        characters_with: "8자리 이상의 문자를 입력하세요",
        inconsistent: "입력한 두 개의 비밀번호가 일치하지 않습니다.",
        set_the_payment_password: "결제 비밀번호가 성공적으로 설정되었습니다!",
        kind_tips: "친절한 팁",
        needs_payment_password: "민감한 작업을 수행할 때 시스템에서 결제 비밀번호를 확인해야 합니다!"
    },
    bscInvite: {
        quick_registration: "빠른 등록",
        welcome_to_join: "가입을 환영합니다",
        we_will_guide_you_through_the_process_of_registering_and_purchasing_vps: "클라우드 호스트 등록 및 구매 과정을 안내해 드립니다.",
        there_are_three_steps: "세 단계가 있습니다",
        please_connect_the_wallet_to_get_the_address_automatically: "시스템이 자동으로 BSC 지갑 주소를 얻습니다",
        current_bsc_wallet: "현재 BSC 지갑",
        next: "다음 단계",
        connect_wallet: "BSC 지갑 연결",
        wallet: "지갑",
        please_enter_the_invitation_information: "초대 정보를 입력하세요",
        my_na_wallet_address: "내 NA 지갑 주소",
        Invitation_na_wallet_address: "NA 지갑 주소 초대",
        please_enter_vps_quantity_and_payment: "클라우드 호스트 수량을 입력하고 USDT를 지불하세요",
        number_of_vps_purchased: "구매한 클라우드 호스트 수",
        usdt_quantity_to_be_paid: "USDT 수량을 지불해야 합니다",
        please_wait_patiently_for_the_transaction_to_take_effect: "거래가 적용될 때까지 기다려 주십시오",
        congratulations_you_have_completed_the_purchase: "축하합니다! 클라우드 호스트 구매를 완료하셨습니다",
        view_in_nastation: "\"TokenPocket\" 지갑의 계정을 확인하세요",
        copy_transaction_hash: "거래 해시 복사",
        copy_my_quick_invitation_link: "내 빠른 초대 링크 복사",
        copy_my_default_invitation_link: "내 기본 초대 링크 복사",
        copy_the_nastation_download_link: "TokenPocket 다운로드 링크 복사",
        copy_official_twitter_link: "공식 Twitter 링크 복사",
        contract_information: "계약 정보",
        the_contract_is_deployed_in_the_security_smart_chain_bsc_and_the_contract_has_been_open_source: "계약이 바이낸스 스마트 체인(BSC)에 배포되었으며, 계약이 오픈 소스였습니다",
        you_can_safely_authorize_and_call_the_functions_of_the_contract: "계약의 기능을 안전하게 승인하고 호출할 수 있습니다",
        view_contract_code: "계약 코드 보기",
        cancel_contract_authorization: "계약 승인 취소",
        contact_us: "문의하기",
        about: "약",
        please_use_the_wallet_that_supports_bsc_chain_to_open_this_page: "이 페이지를 열려면 BSC 체인을 지원하는 지갑을 사용하세요",
        please_enter_my_na_wallet_address: "NA 지갑 주소를 입력해주세요",
        please_enter_the_invitation_na_wallet_address: "초대장 NA 지갑 주소를 입력하세요",
        wrong_purchase_quantity: "구매 수량이 잘못되었습니다.",
        connections_wallet: "지갑 연결 중",
        sorry_your_credit_is_running_low: "잔고 부족",
        contract_call_failed_Please_check_the_authorization_status: "계약 호출 실패, 승인 상태를 확인하십시오",
        your_registration_is_being_confirmed: "귀하의 등록이 확인 중입니다",
        reminder: "알림",
        payment_succeeded: "결제 성공",
        your_authorization_is_being_confirmed: "귀하의 승인이 확인 중입니다",
        loading_cont_pay: "계약금 지급 로드 중",
        connections_the_bsc_wallet: "BSC 지갑 연결 중",

        you_will_complete_the_threestep_quick_registration_process: "3단계 빠른 등록 절차를 완료하게 됩니다",
        must: "반드시",
        Recommended_na_wallet_address: "추천 NA 지갑 주소",
        nickname_required: "닉네임: (필수)",
    },
    faq2: {
        title1: 'GAThain이란 무엇입니까?',
        content1: 'NA Chain은 공유 체인 트랙의 새로운 웹 3.0 공유 체인으로 애플리케이션 개발자에게 친근한 경험을 제공하는 탈중심화 인프라 플랫폼에 초점을 맞추고 있다. 이더리움 등 다른 공공 블록체인의 적용을 크로스 체인 형태로 지원하는 것이 가능하며 개발 초기부터 차세대 공유 체인 생태로 정의되고 있다.이더리움 및 비트코인과 어깨를 나란히하고 이를 넘어설 새로운 시대에 필요한 원스톱 개발 툴로 인식되고 있습니다.',
        title2: 'Terra Focus Mine에 참여하는 방법은 무엇입니까?',
        img1: require("../../assets/img/how-to-join-ko-KR.jpg"),
        title3: 'Terra Focus Mine에 참여하기 위한 소득 보장은 무엇입니까?',
        content2: "1년 후 계약이 만료될 때까지 일일 생산 능력을 자유롭게 이전할 수 있습니다. 1년 이내에 이체하지 않으면 합의 보장 메커니즘에 참여하여 최소 2000U의 수입을 보장할 수 있습니다!",
        img2: require("../../assets/img/3.jpg")
    }
}

export default ko_KR;