<template>
  <div id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
  
  <style scoped lang="less">
</style>
  