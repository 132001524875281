const ja_JP = {
    applyCommunity: {
        community_application: "コミュニティ アプリケーション",
        application_details: "アプリケーションの詳細",
        independent_community_a_new_starting_point: "独立したコミュニティ,新しい出発点",
        destruction: "破壊",
        privilege: "特権",
        top_community: "トップ コミュニティ",
        duty: "義務",
        maintenance_and_development: "メンテナンスと開発",
        describe: "説明",
        welcome_to_the_Gat_Mining_Pool_community: "TFMine コミュニティへようこそ",
        when_you_apply_successfully_you_will_get_an_independent_community_network: "申請に成功すると,独立したコミュニティ ネットワークを取得できます",
        now_start_your_community_career: "さあ,コミュニティでのキャリアを始めてください",
        fortunately_you_will_get_a_new_start: "幸いなことに,あなたは新たなスタートを切ることができます",
        community_leader: "コミュニティ リーダー",
        your_community_members: "あなたのコミュニティ メンバー",
        they_will_be_close_together: "彼らは一緒に近くなります",
        apply_immediately: "すぐに適用",
    },

    applySubsidy: {
        apply_for_accounting_subsidy: "計算能力補助金を申請する",
        application_confirmation_form: "申請確認フォーム",
        apply: "アプリケーション",
        remaining_application_times: "アプリケーションの残り回数",
        fees_required_for_application: "申し込みに必要な料金",
        effective_time_of_subsidy: "補助金有効時間",
        some_tips: "ヒント",
        if_your_twice_calculated_allowance: "あなたの2回計算許容量の場合",
        lasts_for_2_years: "2 年間有効",
        if_the_income_does_not_reach_2000u_you_can_directly_apply_for_the_minimum_pool_subsidy: "収入が 2000U に達していない場合は,最小プール補助金を直接申請できます",
        but_it_is_a_first_come_first_served_principle_if_the_balance_of_the_minimum_pool_is_consumed_it_will_stop: "ただし,先着順の原則であり,最小プールの残高が消費された場合は停止します",
        confirm_application: "申請の確認",
        encountered_a_problem: "問題が発生しました",
        reminder: "リマインダー",
        are_you_sure_to_apply: "本当に応募しますか?",
        confirm: "確認",
        cancel: "キャンセル",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",
    }
    ,
    articleList: {
        real_time_articles: "リアルタイム記事",
        see: "表示",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        close: "閉じる",

        /*1*/
        notice: "お知らせ",
        search_title: "検索タイトル",
        reminder: "リマインダー",
        data_loading: "データ読み込み"

    },

    /*1*/
    articleContent: {
        article_details: "記事の詳細",
        return: "リターン",
    },

    bind: {
        wallet_binding: "ウォレットバインディング",
        bind_bsc_wallet: "BSC ウォレットをバインド",
        recharge_at_any_time: "いつでも充電",
        wallet_association_binding_at_one_time: "ウォレットの関連付け,一度にバインド",
        public_chain_wallet_binding: "パブリック チェーン ウォレットのバインディング",
        bind_public_chain_wallet_address: "パブリック チェーン ウォレット アドレスのバインド",
        more_public_links_will_be_connected_one_time_binding: "TFMine は,より多くのパブリック リンクに接続されます。1 回のバインディング。",
        transfer_at_any_time: "いつでも転送",
        current_wallet: "現在のウォレット",
        click_the_link_wallet_at_the_top_right: "右上をクリックしてウォレットを接続",
        must: "Required",
        bind_public_chain: "公開チェーンをバインド",
        readonly: "読み取り専用",
        wallet_address: "BSC ウォレットアドレス",
        the_transaction_is_being_confirmed: "トランザクションは確認中です...",
        confirm_binding: "バインディングの確認",
        back_to_home: "ホームに戻る",
        bound: "バインド",
        public_chain_name: "パブリックチェーン名",
        binding_status: "バインディングステータス",
        unbound: "アンバウンド",
        copy_address: "コピーアドレス",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        please_link_the_wallet_first: "最初にウォレットをリンクしてください",
        please_enter_the_bsc_wallet_address: "BSC ウォレットのアドレスを入力してください",
        the_wallet_address_is_illegal: "ウォレットアドレスが不正です",
        wallet_address_length_is_fixed_to_42_digits: "ウォレットアドレスの長さは42桁固定です",
        reminder: "リマインダー",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",

        wallet_binding_list: "ウォレットバインディングリスト",
        public_chain: "公開チェーン",
        wallet: "財布",
        state: "状態",
        data_loading: "データ読み込み"
    }
    ,
    contact: {
        contact_us: "お問い合わせ",
        kuala_lumpur_malaysia: "クアラルンプール,マレーシア",
        service_and_maintenance_center: "サービスおよびメンテナンス センター",
        telephone_call: "電話",
        middle_east_dubai: "中東ドバイ",
        sales_center: "セールスセンター",
        other_contact_information: "その他の連絡先情報",

        keep_in_contact: "連絡を取り合う",
        fill_in_the_form_and_contact_us: "フォームに記入してお問い合わせください",
        nickname: "あだ名",
        mailbox: "メールボックス",
        content: "コンテンツ",
        send_out: "送信",
        contact_address: "連絡先",
        social_data: "ソーシャルデータ",
    }
    ,
    faq: {
        common_problem: "一般的な問題",
        really_we_are_often_asked_this_question: "本当に,よくこの質問をされます",
        we_made_this_small_part_to_help_you_determine_what_you_need_faster: "必要なものをより早く判断できるように,この小さなパーツを作成しました",

        some_common_problems: "よくある問題",
        you_are_also_experiencing_problems: "あなたも問題を抱えています",
        feel_free_to_contact_us: "いつでもご連絡ください",
        send_mail: "メール送信",
    }
    ,
    giveUp: {
        abandonment_of_financial_subsidies: "確認",
        are_you_sure_to_give_up_the_consensus_computing_subsidy_of_id_vps: "TFM のコンセンサス コンピューティング補助金を放棄してもよろしいですか?",
        if_free_transfer_is_required_during_the_lease_contract_it_will_be_deemed_as_an_opportunity_to_voluntarily_give_up_the_consensus_guarantee: "リース契約中に無料譲渡が必要な場合は,コンセンサス保証を自発的に放棄する機会と見なされます",
        if_confirmed_the_transfer_extraction_function_can_be_started: "確認された場合,転送抽出機能を開始できます",
        in_effect: "発効中",
        do_not_pay_repeatedly: "繰り返し支払わないでください",
        confirm_abandonment: "放棄の確認",
        return_to_vps_list: "TFM リストを返す",
        reminder: "リマインダー",
        are_you_sure_you_want_to_give_up_if_you_dont_know_whats_going_on: "本当に諦めますか? 何が起こっているのかわからない場合は",
        please_close_this_window: "このウィンドウを閉じてください",
        confirm: "確認",
        cancel: "キャンセル",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",

        tips: "ヒント",
        operating_tips: "操作のヒント",
        the_current_interface_has_sensitive_operations_please_confirm_before_operating: "現在のインターフェイスには機密性の高い操作があります。操作する前に確認してください",
        are_you_sure_to_give_up: "あなたはきっと諦めます",
        tfms_consensus_computing_subsidy: "TFM のコンセンサス コンピューティング補助金はありますか?",
        return: "リターン",
    }
    ,
    home: {
        nac_daily_closing_price:"GAT の終値",
        account_level: "アカウントレベル",
        add_date: "登録日",
        total_value: "合計値",
        total: "合計",
        daily_output: "毎日の生産量",
        account_nickname: "ニックネーム",
        recommended_account: "推奨アカウント",
        evangelist_level: "共有レベル",
        direct_preaching: "シェアダイレクトプッシュ",
        evangelical_exchange_coupon: "クーポンを共有する",
        loss_of_preaching: "共有損失",
        sermon_status: "共有ステータス",
        free_account: "無料アカウント",
        paid_account: "有料アカウント",
        due_account: "未払いアカウント",
        sermon: "説教",
        withdrawal: "撤回",
        bill: "請求書",
        recharge: "リチャージ",
        account_assets: "アカウント資産",
        here_the_asset_details_of_the_account_will_be_listed: "ここに,アカウントの資産の詳細が表示されます",
        wallet: "財布",
        withdrawable_currency: "引き出し可能な通貨",
        deposit_wallet: "リチャージウォレット",
        withdrawable_currency_leasable: "引き出し可能でリース可能",
        community_and_team: "コミュニティとチーム",
        embrace_more_community_members: "より多くのコミュニティ メンバーを受け入れる",
        the_platform_will_provide_new_growth_space_and_create_a_new_starting_point_for_you: "プラットフォームは新しい成長スペースを提供し,新しい出発点を作成します",
        my_team: "私のチーム",
        invitation_link: "招待リンク",
        hey_hello: "こんにちは",
        welcome_to_Gat_Mining_Pool_please_click_the_icon_in_the_upper_right_corner_to_connect_the_wallet: "TFMineへようこそ。右上隅のアイコンをクリックしてウォレットを接続してください",
        close: "閉じる",
        wallet_not_connected: "ウォレットが接続されていません",
        your_browser_does_not_support: "お使いのブラウザはサポートしていません",
        send_data: "送信データ",
        sending_data: "送信データ",
        data_returned: "返されたデータ",
        error: "エラー",

        wallet_address: "ウォレットアドレス",
        team: "チーム",
        number_of_leases: "リースの数",
        number_of_direct_referrals: "直接紹介の数",
        team_account_total: "チームサイズ",
        team_tfm_quantity: "チーム TFM 数量",
        account_wallet: "アカウントウォレット",
        account_details_: "アカウントの詳細",
        left_and_right_sliding: "にスライド",
        account_status: "アカウントのステータス",
        income_sharing_coupon: "収入を分配する",
        loss_of_sharing: "共有の喪失",
        platform_interconnection: "プラットフォーム",
        invite_friends: "友達を招待",
        invite_your_friends_and_earn: "友達を招待して稼ぐ",
        invite_now: "今すぐ招待",
        join_our_discussion_group: "私たちのディスカッショングループに参加してください",
        lets_talk_about_the_market_and_strategy: "市場と戦略について話しましょう",
        join_now: "今すぐ参加",
        view_groups: "グループを表示",
        follow_us: "フォローしてください",
        follow_social_accounts_closely: "ソーシャルアカウントを密接にフォロー",
    }

    ,
    inc_menu: {
        home_page: "ホーム",
        lease: "リース",
        data: "データ",
        set_up: "設定",
        recharge: "リチャージ",
        withdrawal: "撤回",
        invitation: "招待",
        binding: "バインディング",
        connect_wallet: "ウォレットを接続",
        disconnect_wallet: "ウォレットの切断",
        team: "チーム",
        notice: "お知らせ",
        bill: "請求書",
        close: "閉じる",
        welcome_to_Gat_Mining_Pool_please_click_the_icon_in_the_upper_right_corner_to_connect_the_wallet: "TFMine へようこそ。右上隅のアイコンをクリックしてウォレットを接続してください",
    }
    ,
    index: {}
    ,
    invite: {
        invitation_confirmation: "招待の確認",
        welcome_to_join: "参加へようこそ",
        a_data_center_worthy_of_your_trust_the_platform_will_create_a_new_starting_point_for_you: "",
        current_wallet: "現在のウォレット",
        click_the_link_wallet_at_the_top_right: "右上をクリックしてウォレットを接続",
        must: "Required",
        invitation_wallet: "招待ウォレット",
        nickname: "あだ名",
        confirm_binding: "バインディングの確認",
        back_to_home: "ホームに戻る",
        please_link_the_wallet_first: "最初にウォレットをリンクしてください",
        the_current_wallet_cannot_be_the_same_as_the_invitation_wallet_please_switch_wallets: "現在のウォレットは招待ウォレットと同じにすることはできません。ウォレットを切り替えてください",
        please_enter_the_invitation_wallet_address: "招待ウォレットのアドレスを入力してください",
        the_invitation_wallet_is_illegal: "招待ウォレットは違法です",
        the_invitation_wallet_has_a_fixed_length_of_34_digits: "招待ウォレットの長さは42桁の固定長です",
        please_enter_your_nickname: "ニックネームを入力してください",
        nickname_length_cannot_exceed: "ニックネームの長さは 50 文字を超えることはできません",
        you_have_bound: "バインドしました",
        reminder: "リマインダー",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        account_save_ok: "おめでとうございます! アカウントの登録が完了しました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",

        wallet_binding: "ウォレットバインディング",
        the_local_data_center_in_south_korea_is_safe_and_reliable: "シンガポールのローカルデータセンターは安全で信頼できます",
        outstanding_efficiency: "優れた効率",
        click_on_the_top_right_to_connect_the_wallet: "右上をクリックしてウォレットを接続",
        required: "必須",
    }
    ,
    nodeList: {
        my: "私のTFM",
        total: "TFM合計",
        expiring_soon: "もうすぐ期限切れ",
        to_be_activated: "有効にする",
        show_all: "すべて表示",
        hide_paid: "有料を隠す",
        pay_all: "すべて支払う",
        reminder: "リマインダー",
        the_payment_transaction_is_being_uploaded_please_refresh_later: "支払いトランザクションをアップロードしています。後で更新してください",
        note_you_currently_exist: "注: あなたは現在存在しています",
        vps_orders_have_not_been_paid: "TFM 注文は支払われていません",
        entryintoforce_time: "発効時刻",
        greater_than: "より大きい",
        total_value: "合計値",
        computational_wallet: "計算ウォレット",
        transferred: "譲渡",
        abandonment_of_financial_subsidies: "財政補助金の放棄",
        wallet_transfer: "ウォレット転送",
        apply_for_subsidy: "補助金を申請する",
        calculation_income_list: "計算上の収入リスト",
        no_more_data_loaded: "これ以上データがロードされていません",
        data_loading: "データ読み込み",
        return: "リターン",
        the_referrer_of_the_current_account_is_not_in_official_status: "現在のアカウントの紹介者は正式なステータスではありません",
        the_total_number_of_nac_required_for_orders_waiting_to_be_paid_is_incorrect: "支払い待ちの注文に必要な GAT の総数が正しくありません",
        the_order_waiting_for_payment_is_empty: "支払い待ちの注文は空です",
        payment_confirmation: "支払い確認",
        you_confirm_to_pay_selfdetail_totalpaidtotaldeposit_for_contract_orders: "契約注文の GAT デポジットを支払うことを確認します。合計注文数: ",
        confirm: "確認",
        cancel: "キャンセル",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",
        accumulative_demand_of_current_unpaid_orders: "現在の未払い注文 GAT の累積需要",
        current_wallet_balance: "現在のウォレットの残高",
        please_transfer_sufficient: "現在のインスタンスに十分な量を転送してください",

        to_be_paid: "支払われる",
        paid: "支払った",
        list: "リスト",
        output_details: "出力の詳細",
        lease_now: "今すぐリース"
    }
    ,
    outputList: {
        calculation_income_list: "計算上の収入リスト",
        you_can_query_the_details_of_vps_output_on_this_page: "このページでTFM出力の詳細を照会できます",
        output_income: "出力収入",
        code: "コーディング",
        quantity: "数量",
        time: "時間",
        explain: "説明",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        data_loading: "データ読み込み",

        output_list: "出力リスト",
        details_of_daily_output: "毎日のアウトプットの詳細",
        output_details: "出力の詳細",
        hash: "ハッシュ",
        reminder: "リマインダー",
    }
    ,
    pay: {
        recharge: "リチャージ",
        wallet: "財布",
        bound: "バインド",
        click_to_bind: "クリックしてバインド",
        please_do_not_use_the_exchange_to_recharge: "リチャージに交換を使用しないでください",
        copy_recharge_contract_address: "リチャージウォレットのアドレスをコピー",
        open_the_wallet_supporting_bsc_chain: "BSC チェーンをサポートするウォレットを開きます",
        enter_the_contract_address_and_recharge_quantity: "ウォレットアドレスとチャージ金額を入力",
        transfer_sent_to_usdt_bsc: "USDT(BSC)送金を送信",
        wait_patiently_for_the_system_to_confirm_receipt: "システムが受領を確認するのを辛抱強く待っています",
        copy_succeeded_please_do_not_use_the_exchange_to_recharge: "正常にコピーされました! リチャージに交換を使用しないでください",
        copy_contract_address: "ウォレットアドレスをコピー",
        recharge_list: "リチャージリスト",
        click_to_recharge: "クリックしてリチャージ",
        button: "ボタン",
        enter_nac_recharge_quantity: "GATリチャージ量を入力してください",
        authorize_transactions_in_the_popup_interface: "ポップアップ インターフェイスでトランザクションを承認する",
        address_of_qr_code_request: "QRコードリクエストのアドレス",

        see: "表示",
        wallet_address: "ウォレットアドレス",
        date: "日付",
        state: "状態",
        reminder: "リマインダー",

        authorized_recharge_USDT: "オーソライズド リチャージ USDT",
        copy_wallet_address: "ウォレットアドレスをコピー",
        please_enter_the_number_of_USDTs_to_recharge: "リチャージするUSDTの数を入力してください",
        define: "OK",
        cancel: "キャンセル",
        amount_error: "金額エラー",
        please_enter_the_correct_number_of_USDTs_to_recharge: "リチャージするUSDTの正しい数を入力してください",
        Insufficient_BNB_absenteeism_fees: "BNB 欠勤手当が不足しています",
        authorizing_payment: "支払いを承認しています",
        transaction_is_being_confirmed: "トランザクションは確認中です",
    }
    ,
    payList: {
        recharge_details: "リチャージの詳細",
        you_can_query_the_recharge_details_on_this_page: "このページでリチャージの詳細を照会できます",
        rechargeable_wallet: "リチャージ可能なウォレット",
        recharge: "リチャージ",
        public_chain_name: "パブリックチェーン名",
        recharge_quantity: "リチャージ量",
        recharge_hash: "リチャージハッシュ",
        recharging_time: "充電時間",
        explain: "説明",
        copy_hash: "ハッシュをコピー",
        see: "表示",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        data_loading: "データ読み込み",

        recharge_list: "リチャージリスト",
        reminder: "リマインダー",
        your_recharge_will_not_arrive_immediately: "リチャージはすぐには届きません",
        please_wait_patiently_for_the_system_to_confirm_receipt: "システムが受領を確認するまで,しばらくお待ちください",
    }
    ,
    register: {
        lease: "リース",
        lease_vps_virtual_machine: "TFM クラウド ホストをリース",
        we_are_guiding_you_into_the_leasing_process: "私たちはあなたをリースプロセスに案内しています",
        maximum_leasable_quantity: "最大リース可能数量: 1000",
        quantity: "数量",
        must: "Required",
        next_step: "次のステップ",
        payment_wizard: "USDT 支払いウィザード",
        you_are_currently_required_to_authorize_payment: "現在,USDT の支払いを承認する必要があります",
        to_be_paid: "支払われるUSDT",
        current_fusd_balance: "現在の USDT 残高",
        confirm_payment: "支払いの確認",
        payment_completed: "支払いが完了しました",
        the_system_is_detecting_your_order_please_wait_patiently_for_the_transaction_to_be_linked: "システムが注文を検出しています。トランザクションがリンクされるまでしばらくお待ちください",
        when_the_confirmation_is_successful_each_copy_will_be_generated_for_you: "確認が成功すると,各コピーが生成されます",
        contract_later: "契約！後で",
        please_pay_the_deposit_nac_to_activate_each_contract: "デポジット GAT を支払って各契約を有効にしてください",
        continue_to_invest: "投資を続ける",
        return_to_vps_list: "TFM リストを返す",
        please_enter_the_lease_quantity: "リース数量を入力してください",
        close: "閉じる",
        operation_confirmation: "動作確認",
        you_have_not_been_invited_yet_this_is_the_first_step_you_need_to_complete_before_investing: "あなたはまだ招待されていません。これは投資前に完了する必要がある最初のステップです",
        are_you_sure_to_jump_to_the_invitation_binding_page: "招待状のバインディング ページにジャンプしてもよろしいですか",
        jump: "ジャンプ",
        the_invitation_account_of_the_current_account_does_not_exist: "現在のアカウントの招待アカウントは存在しません",
        tips: "リマインダー",
        insufficient_assets_please_supplement: "USDT アセットが不足しています。最初に USDT を再充電してください!",
        recharge_immediately: "すぐに充電",
        reminder: "リマインダー",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",

        rent_tfm_virtual_machine: "TFMクラウドホストをレンタル",
        needed: "必要",
        wait_patiently_for_the_link: "辛抱強くリンクを待っています",
        the_system_is_checking_your_order_please_wait_patiently_for_confirmation: "システムが注文を確認しています。確認が完了するまでしばらくお待ちください",
        when_the_order_is_confirmed_the_system_will_generate_each_copy_for_you: "注文が確認されると、システムが各契約を生成します",
        contract: "",
        finally_you_need_to_pay_in_batches: "最後に、TFM 注文に必要な GAT をまとめて支払う必要があります。",
        contractual: "",
        return_to_tfm_list: "TFM リストを返す",

    }
    ,
    settings: {
        set_up: "設定",
        language_selection_english: "英語",
        language_selection_malay: "マレー語",
        language_selection_simplified_chinese: "中国語 (簡体字)",
        language_selection_traditional_chinese: "中国語 (繁体字)",
        please_select_the_international_language_you_are_familiar_with: "ページの国際言語",
        real_time_articles: "リアルタイム記事",
        read_more_about_the_platform: "ニュースとお知らせ",
        night_mode: "ナイトモード",
        switch_to_night_mode: "ナイトモードに切り替え",
        daytime_mode: "昼間モード",
        switch_to_day_mode: "デイモードに切り替え",
        common_problem: "一般的な問題",
        find_answers_for_you: "あなたのために用意された答えを見つけてください",
        upgrade_maintenance: "アップグレードのメンテナンス",
        the_system_is_being_upgraded_please_check_the_status: "システムをアップグレードしています。ステータスを確認してください",
        contact_us: "お問い合わせ",
        you_will_find_some_contact_information: "連絡先情報が見つかります",
        community_settlement: "コミュニティ決済",
        embrace_millions_of_communities_and_witness_ecological_development_together: "何百万ものコミュニティを受け入れ,共に生態系の発展を目の当たりにする",
        when_you_choose_to_trust_Gat_Mining_Pool_you_will_expand_more_contacts_and_increase_unlimited_wealth: "TFMineを信頼することを選択すると,より多くの連絡先を拡大し,無制限の富を増やすことができます",
        start_a_new_career_together: "一緒に新しいキャリアを始めましょう",
        apply_to_the_community: "コミュニティに応募する",
        language_selection: "言語の選択",
        please_select_the_interface_language_you_are_familiar_with: "使い慣れたインターフェイス言語を選択してください",
        english: "英語 [英語]",
        malay: "マレー語 [メラユ語]",
        simplified_chinese: "中国語 (簡体字)",
        traditional_chinese: "中国語 (繁体字)",

        my: "私の",
        routine: "ルーチン",
        language: "言語",
        korean: "韓国語",
        japanese: "日本語",
        notice: "お知らせ",
        style: "スタイル",
        select_page_language: "ページの言語を選択",
        close: "閉じる",
    }
    ,
    share: {
        invitation_link: "招待リンク",
        invite_to_join: "Tophis への参加を招待",
        let_your_links_pass_on_to_everyone_and_build_a_continuous_network: "あなたのリンクを全員に伝え,継続的なネットワークを構築しましょう",
        invitation_process: "招待プロセス",
        download_nastation_wallet: "TokenPocket ウォレットをダウンロード",
        enter_the_invitation_link_on_the_discovery_page: "発見ページに招待リンクを入力してください",
        confirm_to_bind_the_invitation_wallet: "招待ウォレットのバインドを確認",
        invitation_qr_code: "招待QRコード",
        note_that_the_domain_name_address_of_the_current_page_used_is_generated_dynamically: "現在使用されているページのドメイン名アドレスは動的に生成されることに注意してください",
        copy_invitation_link: "招待リンクをコピー",

        invitation: "招待",
        quick_invite_registration: "クイック招待登録",
        copy_quick_invitation_link: "クイック招待リンクをコピー",
    }
    ,
    systemStat: {
        platform_statistics: "プラットフォーム統計",
        mission_fund_pool: "ミッション資金プール",
        balance: "バランス",
        total_number_of_communities: "コミュニティの総数",
        consensus_minimum_pool: "コンセンサス最小プール",
        capacity_summary: "容量の概要",
        total_node_output: "完全なノード出力",
        total_quantity: "合計数量",
        leased: "リース",
        daily_output: "毎日の出力",
        nacday: "GAT/日",
        deposit_quantity: "預金数量",
        piece: "",
        day: "日",

        data_statistics: "データ統計",
        sermon_fund: "説教基金",
        system_data: "システムデータ",
        server_time_zone: "サーバーのタイムゾーン:",
        server_time: "サーバー時間:",

    }
    ,
    teamList: {
        direct_invitation: "直接招待",
        thank_you_for_your_efforts_we_will_show_you_the_list_of_accounts_directly_invited: "ご尽力ありがとうございます。直接招待されたアカウントのリストを表示します",
        total_direct_deduction: "総直接控除",
        total_number_of_sermons: "総説教数",
        account_list: "アカウント一覧",
        there_are_3_colors: "3色あります",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",

        team_list: "チームリスト",
        my_level: "私のレベル",
        click_to_go_to_the_invitation_interface: "クリックして招待インターフェースに移動",
        total_number_of_collisions: "衝突の総数",
        collision_balance: "衝突バランス",
        details: "詳細",
        total_number_of_collisions_exceeding_the_upper_limit: "上限を超えた衝突の総数",
        close: "閉じる",
        team_members: "チームメンバー",
        reminder: "リマインダー",
        data_loading: "データ読み込み",
    }
    ,
    transferList: {
        bill_details: "請求書の詳細",
        you_can_query_the_bill_of_assets_on_this_page: "このページで資産表を照会できます",
        account_assets: "アカウント資産",
        code: "コーディング",
        quantity: "数量",
        time: "時間",
        explain: "説明",
        copy_encoding: "コピーエンコーディング",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        data_loading: "データ読み込み",

        bill_list: "請求リスト",
        billing_type: "課金タイプ",
        hash: "ハッシュ",
        reminder: "リマインダー",
    }
    ,
    upgrade: {
        upgrade_maintenance: "アップグレードのメンテナンス",
        maintenance_mode: "メンテナンスモード",
        well_be_back_soon_there_is_a_small_problem_with_our_system: "システムに小さな問題があるため,すぐに戻ってきます",
        thank_you_for_your_patience: "お待ちいただきありがとうございます",
        back_to_home: "ホームに戻る",
        refresh_page: "ページを更新",

        system_upgrade: "システムのアップグレード",
        upgrade_and_maintenance: "アップグレードとメンテナンス",
        in_order_to_provide_better_services_we_have_to_upgrade_some_functions: "より良いサービスを提供するために,いくつかの機能をアップグレードする必要があります",
        please_wait_patiently_for_the_system_to_open: "システムが開くまで気長にお待ちください",
        try_refreshing: "更新してみる",
    }
    ,
    walletCross: {
        wallet_transfer: "ウォレット転送",
        choice: "選択",
        source_wallet: "ソース ウォレット",
        computational_wallet: "計算ウォレット",
        target_wallet: "対象ウォレット",
        wallet: "財布",
        maximum_transferable_quantity: "最大譲渡可能数量",
        transfer_quantity: "転送量",
        must: "Required",
        confirm_transfer: "転送の確認",
        please_enter_the_transfer_quantity_greater_than: "0より大きい転送数量を入力してください",
        please_enter_the_transfer_quantity: "転送量を入力してください",
        reminder: "リマインダー",
        are_you_sure_to_transfer: "転送してもよろしいですか?",
        confirm: "確認",
        cancel: "キャンセル",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        copy_hash: "ハッシュをコピー",
        close: "閉じる",

        balance: "バランス",
        service_charge: "サービス料",
        copy_hash_popup_window: "ハッシュ ポップアップ ウィンドウをコピー",
    }
    ,
    withdraw: {
        withdrawal: "撤回",
        withdrawal_wallet: "出金ウォレット",
        wallet_address: "ウォレットアドレス",
        withdrawal_address: "撤回アドレス",
        readonly: "読み取り専用",
        maximum_withdrawal: "最大引き出し",
        withdrawal_quantity: "引き出し数量",
        must: "Required",
        some_tips: "ヒント",
        click_to_confirm: "クリックして確認",
        button: "ボタン",
        authorize_transactions_in_the_popup_interface: "ポップアップ インターフェイスでトランザクションを承認する",
        wait_patiently_for_the_system_to_finish_processing: "システムが処理を完了するのを辛抱強く待っています",
        confirm_withdrawal: "出金確認",
        copy_hash: "ハッシュをコピー",
        view_list: "リストを見る",
        the_withdrawal_address_cannot_be_empty_please_bind_the_public_chain_wallet_address_first: "引き出しアドレスを空にすることはできません。最初にパブリック チェーン ウォレット アドレスをバインドしてください",
        please_enter_the_correct_withdrawal_quantity: "正しい出金額を入力してください",
        reminder: "リマインダー",
        congratulations_the_transaction_was_successfully_sent: "おめでとうございます。トランザクションは正常に送信されました!",
        close: "閉じる",
        apply_for_withdrawal: "出金申請",
        wallet_authorized_withdrawal__waiting_for_confirmation_on_the_chain: "ウォレットの承認された引き出し - チェーンでの確認を待っています",
        withdrawal_token: "出金トークン",
        withdrawal_address_required: "引き出しアドレス (必須",
        current_wallet_address: "現在のウォレットアドレス",
        withdrawal_quantity_required: "出金数量 (必須",
        minimum_withdrawal: "最低出金額",
        service_charge: "サービス料",
        copy_hash_popup_window: "ハッシュ ポップアップ ウィンドウをコピー",
        the_minimum_withdrawal_quantity_is: "最低出金額は",

    }
    ,
    withdrawList: {
        withdrawal_details: "出金の詳細",
        you_can_query_the_details_of_withdrawal_on_this_page: "このページで引き出しの詳細を照会できます",
        withdrawal_wallet: "出金ウォレット",
        withdrawal: "撤回",
        withdrawal_code: "撤回コード",
        withdrawal_quantity: "引き出し数量",
        withdrawal_address: "撤回アドレス",
        withdrawal_time: "撤回時間",
        withdrawal_status: "出金ステータス",
        completed: "完了",
        incomplete: "不完全",
        on_chain_hash: "オンチェーン ハッシュ",
        completion_time: "完了時間",
        explain: "説明",
        copy_hash: "ハッシュをコピー",
        no_more_data_loaded: "これ以上データがロードされていません",
        return: "リターン",
        data_loading: "データ読み込み",

        withdrawal_list: "撤回リスト",
        asset_type: "資産タイプ",
        quantity: "数量",
        hash: "ハッシュ",
        time: "時間",
        reminder: "リマインダー",
    }
    ,
    include: {
        ai_data_center: "",
        bd: "ビッグデータ",
        metaverse: "メタバース",
        hc: "ハードウェア コンピューティング",
        project_provide: "プロジェクト提供",
        bank_trust: "信託銀行",
        home_page: "ホームページ",
        lease: "リース",
        data: "データ",
        settings: "設定",
        back: "戻る",
        link_wallet: "ウォレットをリンク",
        disconnect: "切断",
        download_wallet: "ウォレットをダウンロード",
        kind_tips: "ヒント",
        open_by_nastation: "\"TokenPocket\" ウォレットで dapp を開いてください",
        operation_confirmation: "動作確認",
        are_you_disconnect: "本当にウォレットを切断しますか?",
        cancel: "キャンセル",
        load_more: "もっと読み込む",
        close: "閉じる"
    }
    ,
    jsModule: {
        coming_soon: "近日公開予定",
        copied: "正常にコピーされました」"
    },
    setPayPsw: {

        payment_password: "「支払いパスワード」",

        set_payment_password: "「支払いパスワードの設定」",

        tips: "「支払いパスワードは8桁以上の英字（大文字・小文字）、数字、特殊文字からなる」",

        confirm_payment_password: "「支払いパスワードの確認」",

        submit: "「提出」",

        has_been_set: "「支払パスワードが設定されている」",

        suspayment_password: "「おめでとうございます！支払いパスワードの設定に成功しました！」",

        password_cannot_be_empty: "「パスワードを空にすることはできません」",

        characters_with: "「8桁以上の文字を入力してください」",

        inconsistent: "「2回のパスワード入力が一致しない」",

    },
    bscInvite: {
        quick_registration: "クイック登録",
        welcome_to_join: "参加へようこそ",
        we_will_guide_you_through_the_process_of_registering_and_purchasing_vps: "TFM の登録と購入のプロセスをご案内します",
        there_are_three_steps: "3 つのステップがあります",
        please_connect_the_wallet_to_get_the_address_automatically: "システムは自動的に BSC ウォレット アドレスを取得します",
        current_bsc_wallet: "現在の BSC ウォレット",
        next: "次のステップ",
        connect_wallet: "BSC ウォレットを接続",
        wallet: "財布",
        please_enter_the_invitation_information: "招待情報を入力してください",
        my_na_wallet_address: "私の NA ウォレット アドレス",
        invite_na_wallet_address: "NA ウォレット アドレスを招待",
        please_enter_vps_quantity_and_payment: "TFM数量を入力してUSDTを支払ってください",
        number_of_vps_purchased: "購入したTFMの数",
        usdt_quantity_to_be_paid: "USDT数量を支払う必要があります",
        please_wait_patiently_for_the_transaction_to_take_effect: "トランザクションが有効になるまでしばらくお待ちください",
        congratulations_you_have_completed_the_purchase: "おめでとうございます! TFM の購入が完了しました",
        view_in_nastation: "\"TokenPocket\" ウォレットのアカウントを表示してください",
        copy_transaction_hash: "トランザクション ハッシュをコピー",
        copy_my_quick_invitation_link: "クイック招待リンクをコピー",
        copy_my_default_invitation_link: "デフォルトの招待リンクをコピー",
        copy_the_nastation_download_link: "TokenPocketのダウンロードリンクをコピー",
        copy_official_twitter_link: "公式Twitterリンクをコピー",
        contract_information: "契約情報",
        the_contract_is_deployed_in_the_security_smart_chain_bsc_and_the_contract_has_been_open_source: "コントラクトはバイナンス スマート チェーン (BSC) で展開され,コントラクトはオープン ソースになっています",
        you_can_safely_authorize_and_call_the_functions_of_the_contract: "コントラクトの関数を安全に承認して呼び出すことができます",
        view_contract_code: "契約コードを表示",
        cancel_contract_authorization: "契約承認のキャンセル",
        contact_us: "お問い合わせ",
        about: "約",
        please_use_the_wallet_that_supports_bsc_chain_to_open_this_page: "このページを開くには,BSC チェーンをサポートするウォレットを使用してください",
        please_enter_my_na_wallet_address: "私のNAウォレットアドレスを入力してください",
        please_enter_the_invitation_na_wallet_address: "招待NAウォレットアドレスを入力してください",
        wrong_purchase_quantity: "購入数量が間違っています",
        connecting_wallet: "ウォレットを接続しています",
        sorry_your_credit_is_running_low: "残高が不足しています",
        contract_call_failed_please_check_the_authorization_status: "契約の呼び出しに失敗しました。認証ステータスを確認してください",
        your_registration_is_being_confirmed: "あなたの登録を確認しています",
        reminder: "リマインダー",
        payment_succeeded: "支払いが成功しました",
        your_authorization_is_being_confirmed: "あなたの承認を確認しています",
        loading_cont_pay: "契約支払いを読み込み中",
        connecting_the_bsc_wallet: "BSC ウォレットを接続しています",

        you_will_complete_the_threestep_quick_registration_process: "3 ステップのクイック登録プロセスを完了します",
        must: "Required",
        recommended_na_wallet_address: "NAウォレットの推奨アドレス",
        nickname_required: "ニックネーム: (必須)",
    },
    faq2: {
        title1: 'What is The NA Chain？',
        content1: 'NA Chain is a new basic public chain of web3.0 in the public chain track. It focuses on a decentralized infrastructure platform that provides a friendly experience for application developers, allowing it to support other public blocks such as Ethereum in the form of cross-chain The application of the chain has been defined by a new generation of public chain ecology from the beginning of research and development. It is considered to be on par with Ethereum and Bitcoin in the new era and will surpass it. It is a necessary one-stop development tool.',
        title2: 'How to participate in the Terra Focus Mine?',
        img1: require("../../assets/img/how-to-join-en-US.jpg"),
        title3: 'Income guarantee for participating in the Terra Focus Mine?',
        content2: "The daily production capacity can be transferred out freely until the contract expires in one year. If you do not transfer out within one year, you can participate in the consensus guarantee mechanism to guarantee an income of at least 2000U!",
        img2: require("../../assets/img/2.jpg")




    }
}

export default ja_JP;
