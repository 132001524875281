import { render, staticRenderFns } from "./inc_load_more.vue?vue&type=template&id=4fdb3bb0&scoped=true"
import script from "./inc_load_more.vue?vue&type=script&lang=js"
export * from "./inc_load_more.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdb3bb0",
  null
  
)

export default component.exports