<template>
  <div id="loader">
    <img src="../assets/img/loading-icon.png" alt="icon" class="loading-icon" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
</style>
